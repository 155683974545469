import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cardSliderReadAll: {
    id: 'PU1OGb',
    defaultMessage: 'Read all reviews',
  },
  errorMessage: {
    id: '8aur9O',
    defaultMessage:
      'There was a problem loading product reviews. Please try again later.',
  },
  cardSliderHeading: {
    id: 'cJvElh',
    defaultMessage: 'What {name} customers are saying on',
  },
  cardSliderHeadingAlt: {
    id: 'IObqRv',
    defaultMessage: 'What customers are saying on',
  },
});

export default messages;
